<template>
  <section
    class="dr-cheb"
    :class="classes"
  >
    <div class="dr-cheb-count">
      <div class="dr-cheb-count__item">
        <v-avatar
          color="danger"
          class="mr-8"
        >
          {{ answerLists[0].length }}
        </v-avatar>

        <b v-html="content.l_text"/>
      </div>

      <div class="dr-cheb-count__item dr-cheb-count__item_middle">
        <b v-html="content.m_text"/>
      </div>

      <div class="dr-cheb-count__item">
        <b v-html="content.r_text "/>

        <v-avatar
          color="success"
          class="ml-8"
        >
          {{ answerLists[1].length }}
        </v-avatar>
      </div>
    </div>

    <div class="dr-cheb__content">
      <div class="dr-cheb__answer dr-cheb__answer_position_left">
        <draggable
          :value="answerLists[0]"
          :force-fallback="true"
          :group="{
            name: 'left',
            put: !leftIsMax,
            pull: true
          }"
          :data-group-name="content.placeholder"
          tag="ul"
          animation="200"
          class="dr-cheb-list"
          role="list"
          @change="changeAnswer($event, 0)"
        >
          <li
            v-for="answerLeft in answerLists[0]"
            :key="answerLeft.code"
            class="dr-cheb-list__item"
            role="listitem"
          >
            <div
              v-html="answerLeft.text"
              class="dr-cheb-list__text"
            />
          </li>
        </draggable>
      </div>

      <div
        ref="question"
        class="dr-cheb__question"
      >
        <draggable
          :force-fallback="true"
          :group="{
            name: 'question',
            put: true,
            pull: true
          }"
          :value="questionsPC"
          tag="ul"
          animation="200"
          class="dr-cheb-list"
          role="list"
        >
          <li
            v-for="questionPC in questionsPC"
            :key="questionPC.code"
            class="dr-cheb-list__item"
            role="listitem"
          >
            <button
              @click="moveTo(0, questionPC)"
              class="dr-cheb-list__button dr-cheb-list__button_left"
              role="button"
            >
              <v-icon-svg
                class="rotate-180"
                fill="none"
                stroke="currentColor"
                view-box="0 0 10 18"
                width="1rem"
                height="1rem"
              >
                <svg-icon-chevron2/>
              </v-icon-svg>
            </button>

            <button
              @click="moveTo(1, questionPC)"
              class="dr-cheb-list__button dr-cheb-list__button_right"
              role="button"
            >
              <v-icon-svg
                fill="none"
                stroke="currentColor"
                view-box="0 0 10 18"
                width="1rem"
                height="1rem"
              >
                <svg-icon-chevron2/>
              </v-icon-svg>
            </button>

            <div
              v-html="questionPC.text"
              class="dr-cheb-list__text"
            />
          </li>
        </draggable>
      </div>

      <div class="dr-cheb__answer dr-cheb__answer_position_right">
        <draggable
          :data-group-name="content.placeholder"
          :force-fallback="true"
          :group="{
            name: 'right',
            put: !rightIsMax,
            pull: true
          }"
          :value="answerLists[1]"
          tag="ul"
          animation="200"
          class="dr-cheb-list"
          role="list"
          @change="changeAnswer($event, 1)"
        >
          <li
            v-for="answerRight in answerLists[1]"
            :key="answerRight.code"
            class="dr-cheb-list__item"
            role="listitem"
          >
            <div
              v-html="answerRight.text"
              class="dr-cheb-list__text"
            />
          </li>
        </draggable>
      </div>
    </div>

    <div class="dr-cheb__content dr-cheb__content_is_mobile">
      <div class="dr-cheb__question">
        <ul
          class="dr-cheb-list"
          role="list"
        >
          <li
            v-for="questionMobile in mobileQuestionList"
            v-touch="{
              left: () => swipeHandler(0, questionMobile),
              right: () => swipeHandler(1, questionMobile)
            }"
            v-drag="{
              left: () => swipeHandler(0, questionMobile),
              right: () => swipeHandler(1, questionMobile),
            }"
            :key="`list-mobile__${questionMobile.code}`"
            :class="{
              'dr-cheb-list__item_move_left': questionMobile.direction === 0,
              'dr-cheb-list__item_move_right': questionMobile.direction === 1
            }"
            class="dr-cheb-list__item"
            role="listitem"
          >
            <button
              @click="moveTo(0, questionMobile)"
              class="dr-cheb-list__button dr-cheb-list__button_left"
              role="button"
            >
              <v-icon-svg
                class="rotate-180"
                fill="none"
                stroke="currentColor"
                view-box="0 0 10 18"
                width="1rem"
                height="1rem"
              >
                <svg-icon-chevron2/>
              </v-icon-svg>
            </button>

            <button
              @click="moveTo(1, questionMobile)"
              class="dr-cheb-list__button dr-cheb-list__button_right"
              role="button"
            >
              <v-icon-svg
                fill="none"
                stroke="currentColor"
                view-box="0 0 10 18"
                width="1rem"
                height="1rem"
              >
                <svg-icon-chevron2/>
              </v-icon-svg>
            </button>

            <div
              v-html="questionMobile.text"
              class="dr-cheb-list__text"
            />
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import draggable from 'vuedraggable'
import { Drag, Touch } from '@directives'
import SvgIconChevron2 from '@components/icons/SvgIconChevron2'
import { VAvatar, VIconSvg } from '@components/base'

export default {
  name: 'DragRangingChebur',

  directives: {
    Touch,
    Drag
  },

  components: {
    draggable,
    SvgIconChevron2,
    VAvatar,
    VIconSvg
  },

  props: {
    error: Boolean,
    content: Object,
    values: {
      type: Array,
      defaults: [
        [], // left answers
        [] // right answers
      ]
    }
  },

  computed: {
    classes () {
      return {
        'dr-cheb_is_max-left': this.leftIsMax,
        'dr-cheb_is_max-right': this.rightIsMax,
        'dr-cheb_is_error': this.error
      }
    },
    leftIsMax () {
      return this.answerLists[0].length >= this.content.l_max
    },
    leftIsMin () {
      return this.answerLists[0].length <= this.content.l_min
    },
    rightIsMax () {
      return this.answerLists[1].length >= this.content.r_max
    },
    rightIsMin () {
      return this.answerLists[1].length <= this.content.r_min
    },
    isMax () {
      return this.leftIsMax && this.rightIsMax
    },
    leftAnswersIds () {
      return this.answerLists[0].map(item => item.code)
    },
    rightAnswersIds () {
      return this.answerLists[1].map(item => item.code)
    },
    answersList () {
      return [
        ...this.answerLists[0],
        ...this.answerLists[1]
      ]
    },
    allAnswersDirectionHash () {
      return this.answersList.reduce((result, answer) => ({
        ...result,
        [answer.code]: answer.direction
      }), {})
    },
    mobileQuestionList () {
      return Array
        .from(
          [
            ...this.answersList,
            ...this.questionList
          ]
            .reduce((m, o) => m.set(o.code, o), new Map())
            .values()
        )
        .sort((a, b) => {
          if (a.code < b.code) return -1
          if (a.code > b.code) return 1
          return 0
        })
    },
    variants () {
      return JSON.parse(JSON.stringify(this.content.variant))
    },
    questionsPC () {
      return this.questionList.filter(item => item.direction === null)
    },
    questionList () {
      return this.variants.map(item => {
        if (this.leftAnswersIds.includes(item.code)) {
          item.direction = 0
        } else if (this.rightAnswersIds.includes(item.code)) {
          item.direction = 1
        } else {
          item.direction = null
        }

        return item
      })
    },
    answerLists () {
      return this.values.map(answers => answers.map(answerCode => {
        return this.variants.find(({ code }) => answerCode === code)
      }))
    }
  },

  methods: {
    changeAnswer (event, position) {
      const newAnswers = this.answerLists

      if (event.added) {
        newAnswers[position].splice(event.added.newIndex, 0, event.added.element)
      }

      if (event.removed) {
        newAnswers[position] = newAnswers[position].filter(answer => answer.code !== event.removed.element.code)
      }

      if (event.moved) {
        const fromIndex = newAnswers[position].findIndex(el => el.code === event.moved.element.code)
        newAnswers[position].splice(fromIndex, 1)
        newAnswers[position].splice(event.moved.newIndex, 0, event.moved.element)
      }

      this.change(JSON.parse(JSON.stringify(newAnswers)))
    },

    change (newAnswersState) {
      const answersIds = newAnswersState.flat().map(item => item.code)
      const middleAnswers = this.variants.filter(question => !answersIds.includes(question.code))

      this.$emit('change', newAnswersState, middleAnswers)
    },

    /**
     * Действие при нажатии на кнопки влево/вправо
     * @param {0 | 1} direction - направление перемещения
     * @param {object} item - перемещаемый элемент
     */
    moveTo (direction, item) {
      const limit = [this.content.l_max, this.content.r_max]

      if (this.answerLists[direction].length >= limit[direction]) return

      const newAnswersState = JSON.parse(JSON.stringify(this.answerLists))
      newAnswersState[direction].push(item)

      this.change(newAnswersState)
    },

    /**
     * Обработчик события touch и dnd на мобильных и планшетах
     * Добавляет поле direction к объекту элемента
     * и в дальнейшем определяет по нему его позицию
     * @param {0 | 1} direction - направление перемещения
     * @param {object} item - перемещаемый элемент
     */
    swipeHandler (direction, item) {
      const limit = [this.content.l_max, this.content.r_max]
      const currentDirection = this.mobileQuestionList.find(question => question.code === item.code)?.direction
      const clonedAnswers = JSON.parse(JSON.stringify(this.answerLists))

      switch (currentDirection) {
        case 0: {
          // элемент находится в правом списке
          const answerIndex = this.answerLists[0].findIndex(answer => answer.code === item.code)

          clonedAnswers[0].splice(answerIndex, 1)
          break
        }
        case 1: {
          // элемент в левом списке
          const answerIndex = this.answerLists[1].findIndex(answer => answer.code === item.code)
          clonedAnswers[1].splice(answerIndex, 1)
          break
        }
        default:
          // элемент посередине
          if (this.answerLists[direction].length >= limit[direction]) return
          clonedAnswers[direction].push(item)
      }

      this.change(clonedAnswers)
    }
  }
}
</script>

<style lang="scss" scoped>
@use "sass:map";
@import "~@styles/variables/index.scss";
@import "~@styles/tools/index.scss";
@import "~bootstrap/scss/mixins/breakpoints";

.dr-cheb {
  &__content {
    display: flex;

    @include media-breakpoint-down(md) {
      display: none;
    }

    // скрытие кнопок при преретаскивании
    .sortable-fallback {
      background-color: transparent !important;

      .dr-cheb-list {
        &__text {
          background-color: #FFFFFF !important;
        }

        &__button {
          opacity: 0 !important;
        }
      }
    }

    &_is {
      &_mobile {
        display: none;

        @include media-breakpoint-down(md) {
          display: flex;

          .dr-cheb__question {
            width: 100%;
            padding: 0 4rem;
          }
        }

        @include media-breakpoint-down(sm) {
          .dr-cheb__question {
            padding: 0;
          }
        }
      }
    }
  }

  &__answer {
    width: 30%;
    border: 1px dashed cl(primary);
    border-radius: br(null);
    min-height: 100px;

    @include media-breakpoint-down(md) {
      display: none;
    }

    .sortable-ghost {
      .dr-cheb-list__button {
        display: none;
      }
    }

    &_position {
      &_left,
      &_right {
        .dr-cheb-list {
          // TODO placeholder
          //&:empty {
          //  &::before {
          //    position: absolute;
          //    display: block;
          //    top: 50%;
          //    left: 50%;
          //    transform: translate(-50%, -50%);
          //    width: 100%;
          //    max-width: 60%;
          //    font: 400 1rem $font-primary;
          //    color: rgba(cl(rgb-dark), .3);;
          //  }
          //
          //  &::after {
          //    content: "";
          //    position: absolute;
          //    top: 50%;
          //    left: calc(20% - 1.5rem);
          //    transform: translate(0, -50%);
          //    width: 1rem;
          //    height: 1rem;
          //    background: url("~@/assets/images/icons/plus-3.svg") center no-repeat;
          //    background-size: contain;
          //  }
          //}

          &__text {
            border: none;
          }
        }
      }

      &_left {
        border-color: cl(danger);

        .dr-cheb-list {
          &:empty::before {
            content: attr(data-group-name);
          }

          &__text {
            background-color: rgba(cl(rgb-danger), .1);
          }
        }
      }

      &_right {
        border-color: cl(success);

        .dr-cheb-list {
          &:empty::before {
            content: attr(data-group-name);
          }

          &__text {
            background-color: rgba(cl(rgb-success), .1);
          }
        }
      }
    }
  }

  &__question {
    width: 40%;
  }

  &_is {
    &_max-left {
      .dr-cheb {
        &__question {
          .dr-cheb-list__button_left {
            visibility: hidden;
          }
        }
      }
    }

    &_max-right {
      .dr-cheb {
        &__question {
          .dr-cheb-list__button_right {
            visibility: hidden;
          }
        }
      }
    }

    &_max-right.dr-cheb_is_max-left {
      .dr-cheb {
        &__question {
          .dr-cheb-list__item:not([class*=_move_]) {
            opacity: .5;
          }
        }
      }
    }

    &_error {
      .dr-cheb {
        &__question {
          .dr-cheb-list__text {
            border-color: cl("danger");
          }
        }
      }
    }
  }
}

.dr-cheb-count {
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: .75rem;
  margin-bottom: 1rem;
  background-color: clha($dark, .05);
  border-radius: br(null);

  &__item {
    display: flex;
    width: calc(30% - .75rem);

    &:last-child {
      justify-content: end;
      text-align: right;
    }

    &_middle {
      padding: 0 .75rem;
      width: 40%;
      text-align: center;
      justify-content: center;
    }
  }
}

.dr-cheb-list {
  position: relative;
  height: 100%;
  min-height: 3rem;
  padding: .25rem;
  margin: 0;

  &__item {
    display: flex;
    background-color: #fff;
    cursor: grab;

    &:not(:first-child) {
      margin-top: .5rem;
    }

    &:active {
      .dr-cheb-list__button {
        opacity: 0;
      }
    }

    &_move {
      &_left {
        .dr-cheb-list__button {
          display: none;
        }

        .dr-cheb-list__text {
          position: relative;
          left: -6rem;
          margin: 0 1.75rem;
          border-color: transparent;
          background-color: rgba(cl(rgb-danger), .1);

          @include media-breakpoint-down(sm) {
            left: -2rem;
          }
        }
      }

      &_right {
        .dr-cheb-list__button {
          display: none;
        }

        .dr-cheb-list__text {
          position: relative;
          right: -6rem;
          margin: 0 1.75rem;
          border-color: transparent;
          background-color: rgba(cl(rgb-success), .1);

          @include media-breakpoint-down(sm) {
            right: -2rem;
          }
        }
      }
    }
  }

  &__text {
    order: 2;
    flex: 1 1 auto;
    padding: .5rem;
    border: 1px solid var(--color-border);
    border-radius: br(null);
    transition: border-color .3s ease;
    user-select: none;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    color: cl(primary);
    border: none;
    background-color: transparent;
    cursor: pointer;
    opacity: .6;
    transition: opacity .3s ease;
    user-select: none;

    &:hover {
      opacity: 1;

      ~ .dr-cheb-list__text {
        border-color: cl(primary);
      }
    }

    &_left {
      order: 1;
      color: cl(danger);

      &:hover {
        ~ .dr-cheb-list__text {
          border-color: cl(danger);
        }
      }
    }

    &_right {
      order: 3;
      color: cl(success);

      &:hover {
        ~ .dr-cheb-list__text {
          border-color: cl(success);
        }
      }
    }

  }
}
</style>
